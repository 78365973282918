import {HiOutlineMail} from 'react-icons/hi'
import {FaWhatsapp} from 'react-icons/fa'
import {AiFillLinkedin} from 'react-icons/ai'

const data = [
    {id: 1, icon: <HiOutlineMail/>, link: 'mailto:querynetworking@gmail.com'},
    {id: 2, icon: <AiFillLinkedin/>, link: 'https://www.linkedin.com/in/victortoledodeveloper'},
    {id: 3, icon: <FaWhatsapp/>, link: 'https://wa.me/+543815086763'}
]


// alternative whatsApp link
// https://wa.me/yournumber
// https://api.whatsapp.com/send/?phone=%2B233557097546


export default data