import { useEffect } from 'react'
import faqs from './data'
import FAQ from './FAQ'
import AOS from 'aos'
import 'aos/dist/aos.css'
import './faqs.css'

const FAQs = () => {
  useEffect(() => {
    AOS.init({duration: 2000})
  })

  return (
    <section id="faqs">
      <h2 className='titulo'>Preguntas Frecuentes</h2>
      <p>
      Aquí hay algunas preguntas que suelo recibir. Haga clic para visualizar la respuesta, y si todavía tiene más preguntas, ¡envíeme un mensaje desde la sección de contacto!.
      </p>
      <div className="container faqs__container" data-aos="fade-in">
        {
          faqs.map(faq => (
            <FAQ key={faq.id} faq={faq}/>
          ))
        }
      </div>
    </section>
  )
}

export default FAQs