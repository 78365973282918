import {AiOutlineInstagram} from 'react-icons/ai'
import {AiFillLinkedin} from 'react-icons/ai'
import {AiOutlineTwitter} from 'react-icons/ai'
import {AiFillGithub} from 'react-icons/ai'

const data = [
    {id: 1, link: 'https://www.linkedin.com/in/victortoledodeveloper', icon: <AiFillLinkedin/>},
    {id: 2, link: 'https://github.com/vhtoledo', icon: <AiFillGithub/>},
    {id: 3, link: 'https://twitter.com/vhtdeveloper', icon: <AiOutlineTwitter/>},
    {id: 4, link: 'https://instagram.com/vhtdeveloper', icon: <AiOutlineInstagram/>}
]

export default data
