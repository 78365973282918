import {SiAdobexd} from 'react-icons/si'
import {RiReactjsLine} from 'react-icons/ri'
import {MdDeveloperMode} from 'react-icons/md'
import {AiFillAppstore} from 'react-icons/ai'
import {FaUserTie} from 'react-icons/fa'
import {SiGoogletagmanager} from 'react-icons/si'



const data = [
    {
        id: 1, icon: <SiAdobexd/>, title: 'UI/UX Design', desc: "Diseños modernos e intuitivos. Mi deber asegurarme de que los usuarios se diviertan usando su producto y disfruten de su experiencia."
    },
    {
        id: 2, icon: <RiReactjsLine/>, title: 'Frontend Development', desc: "Su producto se verá bien y será accesible en todos los dispositivos, incluidos teléfonos móviles, tablet y computadoras de escritorio."
    },
    {
        id: 3, icon: <MdDeveloperMode/>, title: 'Backend Development', desc: "La seguridad de su negocio/producto se toma en serio desde el inicio del proyecto. Me aseguro de que su sitio web/app esté a salvo de ataques."
    },
    {
        id: 4, icon: <AiFillAppstore/>, title: 'App Development', desc: 'Creación de aplicaciones que se ejecute en dispositivos IOS y Android, adaptable a su producto.'
    },
    {
        id: 4, icon: <FaUserTie/>, title: 'Product Manager', desc: 'Con mi experiencia como dueño y gerente de productos, encontraremos lo que realmente el usuario necesita y espera de tu producto.'
    },
    {
        id: 4, icon: <SiGoogletagmanager/>, title: 'Product Owner/Scrum Master', desc: 'Como líder de proyecto, desarrollaremos juntos en equipo, cada tarea e iremos avanzado en cada ciclo, para posicionar en el mercado el producto funcionando.'
    }
]



export default data