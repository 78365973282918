import Image1 from '../../assets/project1.jpg'
import Image2 from '../../assets/project2.jpg'
import Image3 from '../../assets/project3.jpg'
import Image4 from '../../assets/project4.jpg'
import Image5 from '../../assets/project5.jpg'
import Image6 from '../../assets/project6.jpg'
import Image7 from '../../assets/project7.jpg'
import Image8 from '../../assets/project8.jpg'
import Image9 from '../../assets/project9.jpg'

const data = [
    {
        id: 1,
        category: 'frontend',
        image: Image1,
        title: "Proyecto WowGames (UIUX)",
        desc: "Ecommerce - Tienda online de Juegos, proyecto desarrollado en NextJSEcommerce -  Tienda online de Juegos, proyecto desarrollado en NextJS.",
        demo: 'https://wowgames.netlify.app/',
        github: 'https://github.com/vhtoledo/ecommerce-next-wowgames'
    },
    {
        id: 2,
        category: 'frontend',
        image: Image2,
        title: "Proyecto Sistema Choferes SUTRAPPA (Frontend)",
        desc: "Sistema de registro único de choferes de taxi SUTRAPPA – Credencial de Chofer habilitado para conducir Taxis en San Miguel de Tucumán.",
        demo: 'https://transportes.smt.gob.ar/',
        github: 'https://github.com/vhtoledo'
    },
    {
        id: 3,
        category: 'frontend',
        image: Image3,
        title: "Proyecto Sistema de Impresión Canon (Frontend)",
        desc: "Sistema de impresión de boleta del impuesto CANON, que habilita a los taxis a prestar servicios dentro de San Miguel de Tucumán.",
        demo: 'http://181.15.244.252/pago/',
        github: 'https://github.com/vhtoledo'
    },
    {
        id: 4,
        category: 'uiux',
        image: Image4,
        title: "Proyecto Sitio Web Oficial DATP (UIUX)",
        desc: "Sitio web de Dirección de Administración y Planificación de Transportes, de la ciudad de San Miguel de Tucumán.",
        demo: 'https://github.com/vhtoledo',
        github: 'https://github.com/vhtoledo'
    },
    {
        id: 5,
        category: 'frontend',
        image: Image5,
        title: "Proyecto Tablero de Tareas (UIUX)",
        desc: "TaakBord es una Aplicación de Administración de tareas, aplicación de lista de estilos kanban basada en la web. ",
        demo: 'https://taakbord.netlify.app/',
        github: 'https://github.com/vhtoledo/TaakBord-App'
    },
    {
        id: 6,
        category: 'backend',
        image: Image6,
        title: "Proyectos Victor Hugo Toledo (Backend)",
        desc: "Repositorios de GITHUB públicos, de Victor Hugo Toledo, puedes encontrar diversidad de proyectos.",
        demo: 'https://github.com/vhtoledo',
        github: 'https://github.com/vhtoledo'
    },
    {
        id: 7,
        category: 'frontend',
        image: Image7,
        title: "Proyecto Sitio Web oficial de Editorial (Frontend)",
        desc: "Sitio web oficial del ACV Folclore. Editorial musical del interior de Argentina",
        demo: 'https://www.acvfolclore.com.ar/',
        github: 'https://github.com/vhtoledo'
    },
    {
        id: 8,
        category: 'frontend',
        image: Image8,
        title: "Proyecto Sitio Web de Alma Music (Frontend)",
        desc: "Sitio web oficial de Alma Music. Sello discográfico, difusión y productora de artistas ",
        demo: 'https://www.almamusic.net.ar/',
        github: 'https://github.com/vhtoledo'
    },
    {
        id: 9,
        category: 'frontend',
        image: Image9,
        title: "Proyecto Sitio Web de Adoclic Advertising (Frontend)",
        desc: "Sitio web oficial de Adoclic Advertising. Especilistas en campañas publicitarias ",
        demo: 'https://adoclic.com/',
        github: 'https://github.com/vhtoledo'
    }
]


export default data