import contacts from './data'
import './contact.css'

const Contact = () => {
  return (
    <section id="contact">
      <h2>Contacto</h2>
      <p>
      ¡Envíame un mensaje a través de cualquiera de los enlaces a continuación!
      </p>
      <div className="container contact__container" data-aos="fade-up">
        {
          contacts.map(contact => <a key={contact.id} href={contact.link} target="_blank" rel="noopener noreferrer">{contact.icon}</a>)
        }
      </div>
      <img src="https://res.cloudinary.com/ddgxkqi07/image/upload/v1687322291/_MG_3605-removebg-preview_i61ebz.png" alt="" className='img' data-aos="fade-up" />
    </section>
  )
}

export default Contact