import data from './data'
import {FaUserGraduate} from 'react-icons/fa'
import { useModalContext } from '../../context/modal-context'
import './navbar.css'

const Navbar = () => {
  const {showModalHandler} = useModalContext();

  return (
    <nav>
      <div className="container nav__container">
        <a href="index.html" className='nav__logo'>
          <img src="https://res.cloudinary.com/ddgxkqi07/image/upload/v1686790864/logo_pymodm.png" alt="Logo" />
        </a>
        <ul className='nav__menu'>
          {
            data.map(item => <li key={item.id}><a href={item.link}>{item.title}</a></li>)
          }
        </ul>
        <button id='theme__icon' onClick={showModalHandler}><FaUserGraduate/></button>
      </div>
    </nav>
  )
}

export default Navbar